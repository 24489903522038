import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  Configuration,
  GetResidentialServiceActivityResponse,
  CommercialServiceActivity,
  ServiceActivityControllerApi,
} from '@/generated'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'

const queryKeys = {
  commercialServiceActivity: 'commercialServiceActivity',
  residentialServiceActivity: 'residentialServiceActivity',
}
function useServiceActivityClient() {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new ServiceActivityControllerApi(new Configuration(config))
  }, [config])
}
export function useCommercialServiceActivityQuery(): UseQueryResult<
  CommercialServiceActivity[]
> {
  const auth = useAuth()
  const serviceActivityClient = useServiceActivityClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [queryKeys.commercialServiceActivity, clientProfileId],
    queryFn: async ({ signal }): Promise<CommercialServiceActivity[]> => {
      return (
        await serviceActivityClient.getCommercialServiceActivity(
          clientProfileId,
          {
            signal,
          }
        )
      ).data.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useResidentialServiceActivityQuery(): UseQueryResult<GetResidentialServiceActivityResponse> {
  const auth = useAuth()
  const serviceActivityClient = useServiceActivityClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [queryKeys.residentialServiceActivity, clientProfileId],
    queryFn: async ({
      signal,
    }): Promise<GetResidentialServiceActivityResponse> => {
      return (
        await serviceActivityClient.getResidentialServiceActivity(
          clientProfileId,
          {
            signal,
          }
        )
      ).data
    },
    enabled: auth.isAuthenticated,
  })
}
