import React, { useMemo, useState } from 'react'
import moment from 'moment'
import {
  ISO_DATE_FORMAT,
  parseRequiredIsoDate,
} from '@/helpers/MomentHelpers.ts'
import { Card } from '@/components/core/cards/Card.tsx'
import DateRangeFilter from '@/components/core/datepickers/DateRangeFilter.tsx'
import {
  lastNDaysPreset,
  lastNMonthsPreset,
  lastNYearsPreset,
  yearToDatePreset,
} from '@/components/core/datepickers/presets.ts'
import { AssetDailyPerformance } from '@/components/graphs/AssetDailyPerformance.tsx'
import AssetMonthlyPerformance from '@/components/graphs/AssetMonthlyPerformance.tsx'
import { ToggleRadioGroup } from '@/components/core/form/ToggleRadioGroup.tsx'
import {
  ACTUAL_ENERGY_SERIES_CONFIG,
  ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG,
  ACTUAL_VS_EXPECTED_SERIES_CONFIG,
  ACTUAL_VS_PREDICTED_SERIES_CONFIG,
  EXPECTED_ENERGY_SERIES_CONFIG,
  GRAPH_GROUP_IDS,
  PREDICTED_ENERGY_SERIES_CONFIG,
  SOLAR_RESOURCE_RATIO_SERIES_CONFIG,
} from '@/components/graphs/types.ts'
import {
  GraphControlsContext,
  GraphControlsGroup,
  useGraphControls,
} from '@/components/core/graphs/useGraphControls.ts'
import { GraphLegend } from '@/components/core/graphs/legend/GraphLegend.tsx'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from '@/components/export/ExportCsvButton'
import { DateRangePreset } from '@/components/core/datepickers/DateRangeSelector.tsx'
import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'

type PerformanceGranularityValue = 'daily' | 'monthly'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AssetPerformanceCsvData = ExportCsvButtonProps<any>

export function AssetPerformanceTab(): React.JSX.Element {
  const { assetId } = useAssetDetailContext()
  const minDate = useMemo(
    () => moment().startOf('day').subtract(18, 'months'),
    []
  )
  const maxDate = useMemo(() => moment().startOf('day').subtract(1, 'day'), [])
  const [granularity, setGranularity] =
    useState<PerformanceGranularityValue>('daily')
  const [selectedDateRange, setSelectedDateRange] = useState({
    isoStartDate: moment()
      .startOf('day')
      .subtract(6, 'months')
      .format(ISO_DATE_FORMAT),
    isoEndDate: moment()
      .startOf('day')
      .subtract(1, 'day')
      .format(ISO_DATE_FORMAT),
  })
  const selectedMinDate = useMemo(
    () => parseRequiredIsoDate(selectedDateRange.isoStartDate),
    [selectedDateRange.isoStartDate]
  )
  const selectedMaxDate = useMemo(
    () => parseRequiredIsoDate(selectedDateRange.isoEndDate),
    [selectedDateRange.isoEndDate]
  )

  const onSelectedDateRangeUpdate = (
    isoStartDate: string,
    isoEndDate: string
  ) => {
    if (
      isoStartDate !== selectedDateRange.isoStartDate ||
      isoEndDate !== selectedDateRange.isoEndDate
    ) {
      setSelectedDateRange({ isoStartDate, isoEndDate })
    }
  }

  const graphGroups = useMemo<GraphControlsGroup[]>(
    () => [
      {
        id: GRAPH_GROUP_IDS.siteProduction,
        seriesType: 'column',
        series: [
          {
            id: ACTUAL_ENERGY_SERIES_CONFIG.id,
            enabled: true,
          },
          {
            id: PREDICTED_ENERGY_SERIES_CONFIG.id,
            enabled: false,
          },
          {
            id: EXPECTED_ENERGY_SERIES_CONFIG.id,
            enabled: true,
          },
        ],
      },
      {
        id: GRAPH_GROUP_IDS.performanceRatios,
        seriesType: 'line',
        series: [
          {
            id: ACTUAL_VS_EXPECTED_SERIES_CONFIG.id,
            enabled: true,
          },
          {
            id: ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG.id,
            enabled: false,
          },
          {
            id: ACTUAL_VS_PREDICTED_SERIES_CONFIG.id,
            enabled: false,
          },
          {
            id: ACTUAL_VS_EXPECTED_SERIES_CONFIG.id,
            enabled: true,
          },
        ],
      },
      {
        id: GRAPH_GROUP_IDS.solarResourceRatio,
        seriesType: 'line',
        series: [
          {
            id: SOLAR_RESOURCE_RATIO_SERIES_CONFIG.id,
            enabled: false,
          },
        ],
      },
    ],
    []
  )

  const dateRangePresets: DateRangePreset[] = useMemo(
    () => [
      ...(granularity === 'daily'
        ? [lastNDaysPreset(7), lastNDaysPreset(30)]
        : ([] as DateRangePreset[])),
      lastNMonthsPreset(3),
      lastNMonthsPreset(6),
      yearToDatePreset(),
      lastNYearsPreset(1),
      lastNMonthsPreset(18),
    ],
    [granularity]
  )

  const graphLegend = useGraphControls({
    initialState: graphGroups,
  })

  const [csvData, setCsvData] = useState<AssetPerformanceCsvData>({
    columns: [],
    fileName: '',
    rows: () => [],
  })

  return (
    <GraphControlsContext.Provider value={graphLegend}>
      <Card>
        <div className="tw-flex tw-flex-col tw-gap-6">
          <div className="tw-inline-flex tw-w-full tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-pb-4">
            <h2 className="tw-text-xl tw-font-bold tw-text-gray-900">
              Asset Performance
            </h2>
            <div className="tw-flex tw-items-center tw-gap-4">
              <DateRangeFilter
                currentMonthPosition="right"
                isoMinDate={minDate.format(ISO_DATE_FORMAT)}
                isoMaxDate={maxDate.format(ISO_DATE_FORMAT)}
                presets={dateRangePresets}
                isoStartDate={selectedDateRange.isoStartDate}
                isoEndDate={selectedDateRange.isoEndDate}
                onApply={(isoStartDate, isoEndDate) => {
                  setSelectedDateRange({
                    isoStartDate,
                    isoEndDate,
                  })
                }}
              />
              <ToggleRadioGroup
                onChange={(value: PerformanceGranularityValue) => {
                  setGranularity(value)
                }}
              >
                <ToggleRadioGroup.RadioButton
                  id="daily"
                  name="perfGranularity"
                  value="daily"
                  defaultChecked
                >
                  Daily
                </ToggleRadioGroup.RadioButton>
                <ToggleRadioGroup.RadioButton
                  id="monthly"
                  name="perfGranularity"
                  value="monthly"
                >
                  Monthly
                </ToggleRadioGroup.RadioButton>
              </ToggleRadioGroup>
              <ExportCsvButton<AssetPerformanceCsvData>
                columns={csvData.columns}
                fileName={csvData.fileName}
                rows={csvData.rows}
              />
            </div>
          </div>
          <div className="tw-flex tw-items-start tw-gap-6">
            <div className="tw-min-w-[200px]">
              <GraphLegend>
                <GraphLegend.Group>
                  <GraphLegend.GroupHeading>
                    Site Production (kWh)
                  </GraphLegend.GroupHeading>
                  <GraphLegend.GroupSeriesTypeSelect
                    groupId={GRAPH_GROUP_IDS.siteProduction}
                  />
                  <GraphLegend.SeriesList>
                    <GraphLegend.Series config={ACTUAL_ENERGY_SERIES_CONFIG}>
                      Site Actual
                    </GraphLegend.Series>
                    {granularity === 'monthly' && (
                      <GraphLegend.Series
                        config={PREDICTED_ENERGY_SERIES_CONFIG}
                      >
                        Site Predicted
                      </GraphLegend.Series>
                    )}
                    <GraphLegend.Series config={EXPECTED_ENERGY_SERIES_CONFIG}>
                      Site Expected
                    </GraphLegend.Series>
                  </GraphLegend.SeriesList>
                </GraphLegend.Group>
                <GraphLegend.Group>
                  <GraphLegend.GroupHeading>
                    Performance Ratios (%)
                  </GraphLegend.GroupHeading>
                  {granularity === 'daily' && (
                    <>
                      <GraphLegend.Subheading>
                        Actual vs Expected Energy
                      </GraphLegend.Subheading>
                      <GraphLegend.SeriesList>
                        <GraphLegend.Series
                          config={ACTUAL_VS_EXPECTED_SERIES_CONFIG}
                        >
                          Daily
                        </GraphLegend.Series>
                        <GraphLegend.Series
                          config={ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG}
                        >
                          Trailing 7 Days
                        </GraphLegend.Series>
                      </GraphLegend.SeriesList>
                    </>
                  )}
                  {granularity === 'monthly' && (
                    <GraphLegend.SeriesList>
                      <GraphLegend.Series
                        config={ACTUAL_VS_PREDICTED_SERIES_CONFIG}
                      >
                        Actual vs Predicted Energy
                      </GraphLegend.Series>
                      <GraphLegend.Series
                        config={ACTUAL_VS_EXPECTED_SERIES_CONFIG}
                      >
                        Actual vs Expected Energy
                      </GraphLegend.Series>
                    </GraphLegend.SeriesList>
                  )}
                </GraphLegend.Group>
                {granularity === 'monthly' && (
                  <GraphLegend.Group>
                    <GraphLegend.GroupHeading>
                      Solar Resource Ratio (%)
                    </GraphLegend.GroupHeading>
                    <GraphLegend.SeriesList>
                      <GraphLegend.Series
                        config={SOLAR_RESOURCE_RATIO_SERIES_CONFIG}
                      >
                        Actual POA/Predicted POA
                      </GraphLegend.Series>
                    </GraphLegend.SeriesList>
                  </GraphLegend.Group>
                )}
              </GraphLegend>
            </div>
            <div className="tw-w-full tw-overflow-auto">
              {granularity === 'daily' && (
                <AssetDailyPerformance
                  assetId={assetId}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectedMinDate={selectedMinDate}
                  selectedMaxDate={selectedMaxDate}
                  onSelectedRangeUpdate={(startDate, endDate) => {
                    onSelectedDateRangeUpdate(
                      startDate.format(ISO_DATE_FORMAT),
                      endDate.format(ISO_DATE_FORMAT)
                    )
                  }}
                  setCsvData={setCsvData}
                />
              )}
              {granularity === 'monthly' && (
                <AssetMonthlyPerformance
                  assetId={assetId}
                  selectedMinDate={selectedMinDate}
                  selectedMaxDate={selectedMaxDate}
                  setCsvData={setCsvData}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </GraphControlsContext.Provider>
  )
}
