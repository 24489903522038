import { useMemo } from 'react'
import { FilterDrawerFilterDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { ResidentialServiceActivity } from '@/generated'
import {
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'

export default function useResidentialServiceActivityTableFilters() {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  return useMemo<
    Array<FilterDrawerFilterDef<ResidentialServiceActivity>>
  >(() => {
    const filters: FilterDrawerFilterDef<ResidentialServiceActivity>[] = []

    filters.push(
      priorityFilter('priority', rowData => rowData.priority),
      pendingClientActionFilter(
        'pendingClientAction',
        serviceActivityPendingClientActionFilterDataAccessor
      ),
      {
        type: 'faceted',
        key: 'ticketType',
        header: 'Ticket Type',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          rowData.ticketType,
      },
      creationReasonFilter(
        'creationReason',
        (rowData: ResidentialServiceActivity) => rowData.creationReason
      ),
      creationDateFilter('createdAt'),
      updatedDateFilter('updatedAt'),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      ),
      {
        type: 'faceted',
        key: 'assetStage',
        header: 'Lifecycle Stage',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          rowData.assetStage?.toString() ?? null,
      }
    )

    return filters
  }, [serviceCloudIntegrationEnabled])
}
