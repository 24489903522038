import React, { useMemo, useState } from 'react'
import { Row } from '@tanstack/react-table'
import { CommercialServiceActivity } from '@/generated'
import { ticketPrioritySort } from '@/components/table/sort/ticketPrioritySort.ts'
import useTable from '@/components/core/table/useTable.tsx'
import { LoadingPage } from '../LoadingPage.tsx'
import Table from '@/components/core/table/Table.tsx'
import {
  useAssetMetadataQuery,
  useAssetServiceActivityQuery,
} from '@/api/AssetQueries.ts'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import {
  isServiceActivityClosed,
  serviceActivityStatusSort,
} from '@/helpers/ServiceActivityHelper.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import moment from 'moment'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import ServiceActivityTableControls from '@/pages/serviceActivity/shared/ServiceActivityTableControls.tsx'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'

import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'

export function AssetServiceActivityTab(): React.JSX.Element {
  const { assetId } = useAssetDetailContext()
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  const serviceActivityQuery = useAssetServiceActivityQuery(assetId, true)

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(
    ServiceActivityPageName.assetServiceActivity
  )

  const exportCsvColumns = useCommercialServiceActivityCsvColumns({
    includeClosedDate: true,
  })

  const filterDefs = useCommercialServiceActivityTableFilters(
    ServiceActivityPageName.assetServiceActivity
  )

  const rows = serviceActivityQuery.data?.data
  const sortedRows = useMemo<CommercialServiceActivity[] | undefined>(
    () => rows?.sort(assetServiceActivityCustomSort),
    [rows]
  )
  const tableModel = useTable(sortedRows, columns)

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }

  return (
    <>
      <h2 className="tw-mb-6 tw-text-xl tw-font-bold tw-text-gray-900">
        Service Activity
      </h2>
      {assetMetadataQuery.data && (
        <ServiceActivityTableControls
          tableModel={tableModel}
          filterDefs={filterDefs}
          rowData={rows}
          csvOptions={{
            exportCsvColumns,
            exportFileName: `${assetMetadataQuery.data.assetName}_service_activity`,
          }}
        />
      )}

      <div className="tw-mt-4 tw-overflow-auto">
        {serviceActivityQuery.isFetching && <LoadingPage />}
        {serviceActivityQuery.isFetched && (
          <Table
            className="tw-shrink"
            model={tableModel}
            rowClassName={rowClassName}
            onRowClick={handleClickRow}
            loading={serviceActivityQuery.isFetching}
            error={serviceActivityQuery.isError}
            noDataMessage={
              <ServiceActivityTableNoDataMessage tableModel={tableModel} />
            }
          />
        )}
        {selectedServiceActivity && (
          <ServiceActivityModal
            serviceActivity={selectedServiceActivity}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </>
  )
}

function rowClassName(row: Row<CommercialServiceActivity>) {
  return twMerge(
    isServiceActivityClosed(row.original) && 'tw-bg-gray-50',
    'hover:tw-bg-gray-50'
  )
}

/**
 * The desired sort order for the initial sort of the asset service activity table cannot be defined as a
 * simple sort by columns. Additionally, some of the sort criteria are not desired to be sortable columns
 * in the table.
 */
function assetServiceActivityCustomSort(
  a: CommercialServiceActivity,
  b: CommercialServiceActivity
) {
  // Closed tickets drop below active tickets
  const isAClosed = isServiceActivityClosed(a)
  const isBClosed = isServiceActivityClosed(b)
  if (isAClosed && !isBClosed) return 1
  if (!isAClosed && isBClosed) return -1
  // If both tickets are closed, sort by status, then by last updated
  if (isAClosed && isBClosed) {
    const statusCompare = serviceActivityStatusSort(a.status, b.status)
    if (statusCompare !== 0) return statusCompare
    const aUpdatedAt = moment(a.updatedAt)
    if (aUpdatedAt.isBefore(b.updatedAt)) return 1
    if (aUpdatedAt.isAfter(b.updatedAt)) return -1
    return 0
  }
  // If both tickets are active, sort by priority
  return ticketPrioritySort(a.priority ?? '', b.priority ?? '')
}
