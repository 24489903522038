import { PageCard } from '@/components/cards/PageCard.tsx'
import ServiceActivityTableControls from '@/pages/serviceActivity/shared/ServiceActivityTableControls.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useState } from 'react'
import { useCommercialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import { useBrandingQuery } from '@/api/ApiQueries.ts'
import { CommercialServiceActivity } from '@/generated'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import { Row } from '@tanstack/react-table'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import { LoadingPage } from '@/pages/LoadingPage.tsx'
import Table from '@/components/core/table/Table.tsx'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'
import PageBody from '@/pages/PageBody.tsx'

const emptyServiceActivityData: CommercialServiceActivity[] = []
export default function CommercialServiceActivityPage() {
  const { data: branding } = useBrandingQuery()
  const serviceActivityQuery = useCommercialServiceActivityQuery()

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(
    ServiceActivityPageName.serviceActivity
  )
  const exportCsvColumns = useCommercialServiceActivityCsvColumns({
    includeAssetDetails: true,
  })
  const filterDefs = useCommercialServiceActivityTableFilters(
    ServiceActivityPageName.serviceActivity
  )
  const rows = serviceActivityQuery.data ?? emptyServiceActivityData
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }
  return (
    <PageBody bgColor="gray">
      <div className="tw-mb-8 tw-flex tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following table shows all open service activity{' '}
            {branding?.name ? `for ${branding.name}` : ''}.
          </div>
        </div>
      </div>
      <PageCard>
        <PageCard.Header>
          <ServiceActivityTableControls
            csvOptions={{ exportCsvColumns: exportCsvColumns }}
            filterDefs={filterDefs}
            tableModel={tableModel}
            rowData={rows}
          />
        </PageCard.Header>
        {serviceActivityQuery.isFetching && <LoadingPage />}
        {serviceActivityQuery.isFetched && (
          <Table
            className="tw-shrink"
            model={tableModel}
            loading={serviceActivityQuery.isFetching}
            error={serviceActivityQuery.isError}
            rowClassName={() => 'hover:tw-bg-slate-50'}
            onRowClick={handleClickRow}
            noDataMessage={
              <ServiceActivityTableNoDataMessage tableModel={tableModel} />
            }
          />
        )}
        {selectedServiceActivity && (
          <ServiceActivityModal
            serviceActivity={selectedServiceActivity}
            onClose={handleCloseModal}
          />
        )}
      </PageCard>
    </PageBody>
  )
}
