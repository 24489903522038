import { Card } from '@/components/core/cards/Card.tsx'
import {
  ServiceActivityAgeBucket,
  ServiceActivityAgeBucketIdEnum,
  ServiceActivitySnapshot,
} from '@/generated'
import React, { useMemo } from 'react'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue, {
  SnapshotValueDisplayType,
} from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import AgeSnapshotItem from '@/pages/serviceActivity/residential/snapShots/components/AgeSnapshotItem.tsx'
import { PercentNumberFormat } from '@/helpers/NumberFormats.ts'

type Props = {
  snapshot?: ServiceActivitySnapshot
  icon: React.JSX.Element
  description: string
  ticketLabel: string
  percentLabel: string
  showAdditionalFields?: boolean
}

const desiredAgeOrder = [
  ServiceActivityAgeBucketIdEnum.New1To29Days,
  ServiceActivityAgeBucketIdEnum.Aging30To59Days,
  ServiceActivityAgeBucketIdEnum.Aging60To89Days,
  ServiceActivityAgeBucketIdEnum.Aging90PlusDays,
]

const sortByBucketAge = (
  a: ServiceActivityAgeBucket,
  b: ServiceActivityAgeBucket
) => desiredAgeOrder.indexOf(a.id) - desiredAgeOrder.indexOf(b.id)

export default function SnapshotCard({
  snapshot,
  icon,
  description,
  ticketLabel,
  percentLabel,
  showAdditionalFields = false,
}: Readonly<Props>) {
  // Memoize sorted age breakdown
  const sortedAgeBreakdown = useMemo(
    () => snapshot?.ageBreakdown.sort(sortByBucketAge) || [],
    [snapshot?.ageBreakdown]
  )

  // Early return if no snapshot is provided
  if (!snapshot) return null

  const {
    countOfServiceActivitiesOpenedForCategory = 0,
    percentageOfAssetsWithServiceActivityOpenedForCategory = 0,
    countOfPendingClientActions,
    countOfAssets,
    countOfNonCoveredServiceRequests,
    countOfRemoteTroubleShootings,
    countOfFieldServices,
  } = snapshot

  // Helper function to create SnapshotLine blocks
  const renderSnapshotLine = (
    label: string,
    value: number,
    displayType: SnapshotValueDisplayType = 'default'
  ) => (
    <SnapshotLine label={label}>
      <SnapshotValue value={value} displayType={displayType} />
    </SnapshotLine>
  )

  return (
    <Card className="tw-h-full">
      <SnapshotSummary
        icon={icon}
        count={countOfAssets}
        description={description}
      />

      <SnapshotSections>
        <SnapshotSection>
          {renderSnapshotLine(
            ticketLabel,
            countOfServiceActivitiesOpenedForCategory,
            'primary'
          )}
          <SnapshotLine
            label={percentLabel}
            description="This is a description"
          >
            <SnapshotValue
              value={PercentNumberFormat().float.format(
                percentageOfAssetsWithServiceActivityOpenedForCategory
              )}
            />
          </SnapshotLine>
        </SnapshotSection>

        <SnapshotSection>
          {renderSnapshotLine(
            'Pending client action',
            countOfPendingClientActions,
            'danger'
          )}
        </SnapshotSection>

        <SnapshotSection show={showAdditionalFields}>
          {renderSnapshotLine(
            'Non-Covered Service Requests',
            countOfNonCoveredServiceRequests
          )}
        </SnapshotSection>
        <SnapshotSection show={showAdditionalFields}>
          {renderSnapshotLine(
            'Remote Troubleshooting',
            countOfRemoteTroubleShootings
          )}

          {renderSnapshotLine('Field Service', countOfFieldServices)}
        </SnapshotSection>

        <SnapshotSection>
          {sortedAgeBreakdown.map((ageBucket, index) => (
            <AgeSnapshotItem key={index} ageBucket={ageBucket} />
          ))}
        </SnapshotSection>
      </SnapshotSections>
    </Card>
  )
}
