import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import {
  ServiceActivityAgeBucket,
  ServiceActivityAgeBucketIdEnum,
} from '@/generated'
import { PercentNumberFormat } from '@/helpers/NumberFormats.ts'

type Props = {
  ageBucket: ServiceActivityAgeBucket
}

const labelMap = new Map([
  [ServiceActivityAgeBucketIdEnum.New1To29Days, 'New (1-29 days)'],
  [ServiceActivityAgeBucketIdEnum.Aging30To59Days, 'Aging (30-59 days)'],
  [ServiceActivityAgeBucketIdEnum.Aging60To89Days, 'Aging (60-89 days)'],
  [ServiceActivityAgeBucketIdEnum.Aging90PlusDays, 'Aging (90+ days)'],
])
export default function AgeSnapshotItem({ ageBucket }: Readonly<Props>) {
  return (
    <SnapshotLine label={labelMap.get(ageBucket.id) ?? ''}>
      <SnapshotValue value={ageBucket.count} />
      <SnapshotValue
        value={PercentNumberFormat().float.format(ageBucket.percentage)}
        displayType="badge"
      />
    </SnapshotLine>
  )
}
