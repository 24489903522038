import { PropsWithChildren } from 'react'
import { PageBodyBgColor } from '@/pages/PageBodyBgColor.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface PageBodyProps extends PropsWithChildren {
  bgColor: keyof typeof PageBodyBgColor
}

export default function PageBody({
  bgColor = 'gray',
  children,
}: Readonly<PageBodyProps>) {
  const style = twMerge('tw-h-page tw-px-20 tw-py-8', PageBodyBgColor[bgColor])
  return <div className={style}>{children}</div>
}
