import infoIcon from '@iconify/icons-heroicons/information-circle'
import warnIcon from '@iconify/icons-material-symbols/warning'
import { Icon } from '@iconify/react/offline'
import { CellContext, ColumnDef, Row } from '@tanstack/react-table'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import {
  useAssetsPerformanceQuery,
  useBrandingQuery,
} from '@/api/ApiQueries.ts'
import IconExpanderClosed from '@/assets/icons/expander-closed.svg?react'
import IconExpanderOpen from '@/assets/icons/expander-open.svg?react'
import Button from '@/components/core/buttons/Button'
import DateRangeFilter from '@/components/core/datepickers/DateRangeFilter.tsx'
import {
  lastNDaysPreset,
  lastNMonthsPreset,
  yesterdayPreset,
} from '@/components/core/datepickers/presets.ts'
import {
  CsvColumnOption,
  ExportCsvButton,
} from '@/components/export/ExportCsvButton.tsx'
import { Modal } from '@/components/core/modals/Modal.tsx'
import { Tooltip } from '@/components/core/tooltip/Tooltip'
import { Aggregations } from '@/components/core/table/aggregation.ts'
import numericColumn, {
  Formats,
} from '@/components/core/table/columns/numericColumn.ts'
import textColumn from '@/components/core/table/columns/textColumn'
import SearchInput from '@/components/core/table/SearchInput.tsx'
import Table from '@/components/core/table/Table.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { TotalDef, useTotals } from '@/components/core/table/useTotals.tsx'
import { ISO_DATE_FORMAT } from '@/helpers/MomentHelpers.ts'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { LoadingPage } from './LoadingPage'
import { PageTitle } from '@/components/text/PageTitle.tsx'
import { PageCard } from '@/components/cards/PageCard.tsx'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { AssetPerformanceRow, CommercialServiceActivity } from '@/generated'
import { AssetDetailLink } from './assetdetail/AssetDetailLink.tsx'
import { useAssetServiceActivityQuery } from '@/api/AssetQueries.ts'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import { ActualVsExpectedCell } from '@/components/table/cells/ActualVsExpectedCell.tsx'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'
import PageBody from '@/pages/PageBody.tsx'

const weightedAverageTooltip =
  'Total reflected is a\nweighted average to\nconsider system size'

export function PortfolioOverviewPage(): React.JSX.Element {
  return <AssetsPerformanceTable />
}

function canRowExpand(row: Row<AssetPerformanceRow>): boolean {
  const openServiceActivityCount =
    row.getValue<number | undefined>('openServiceActivityCount') ?? 0
  return openServiceActivityCount > 0
}

function ExpanderIcon(
  ctx: Readonly<CellContext<AssetPerformanceRow, unknown>>
) {
  if (ctx.row.getCanExpand()) {
    return (
      <div className="tw-cursor-pointer tw-select-none">
        {ctx.row.getIsExpanded() && <IconExpanderOpen />}
        {!ctx.row.getIsExpanded() && <IconExpanderClosed />}
      </div>
    )
  }
  return <></>
}

function AssetsPerformanceTable(): React.JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState({
    isoStartDate: moment()
      .startOf('day')
      .subtract(7, 'days')
      .format(ISO_DATE_FORMAT),
    isoEndDate: moment()
      .startOf('day')
      .subtract(1, 'day')
      .format(ISO_DATE_FORMAT),
  })

  const { data: branding } = useBrandingQuery()
  const assetsPerformanceQuery = useAssetsPerformanceQuery(
    selectedDateRange.isoStartDate,
    selectedDateRange.isoEndDate
  )
  const showAssetManagerColumn = useHasMultipleAssetManagers()

  const columns = useMemo((): ColumnDef<AssetPerformanceRow>[] => {
    const columnDefs: ColumnDef<AssetPerformanceRow>[] = []
    columnDefs.push({
      id: 'expander',
      header: () => null,
      meta: {
        headerClassName: 'tw-w-0',
      },
      cell: ExpanderIcon,
    })
    if (showAssetManagerColumn) {
      columnDefs.push(
        textColumn({
          key: 'assetManagerName',
          header: 'Asset Manager',
          mode: 'filterable-facets',
        })
      )
    }
    columnDefs.push(
      textColumn({
        key: 'assetName',
        header: 'Asset Name',
        mode: 'sortable',
        cell: AssetNameCell,
      }),
      numericColumn({
        key: 'systemSize',
        header: 'System Size (kWDC)',
        mode: 'sortable',
        format: 'integer',
      }),
      numericColumn({
        key: 'actualEnergy',
        header: 'Actual Energy (kWh)',
        mode: 'sortable',
        format: 'integer',
      }),
      numericColumn({
        key: 'predictedEnergy',
        header: 'Predicted Energy (kWh)',
        mode: 'sortable',
        format: 'integer',
      }),
      numericColumn({
        key: 'expectedEnergy',
        header: 'Expected Energy (kWh)',
        mode: 'sortable',
        format: 'integer',
      }),
      numericColumn({
        key: 'actualVsPredictedEnergy',
        header: 'Actual vs Predicted (%)',
        mode: 'sortable',
        format: 'percent',
      }),
      numericColumn({
        key: 'actualVsExpectedEnergy',
        header: 'Actual vs Expected (%)',
        mode: 'sortable',
        format: 'percent',
        cell: ActualVsExpectedCell,
      }),
      numericColumn({
        key: 'solarResourceRatio',
        header: 'Solar Resource Ratio (%)',
        mode: 'sortable',
        format: 'percent',
      }),
      numericColumn({
        key: 'openServiceActivityCount',
        header: 'No. of Open Service Activity',
        mode: 'sortable',
        format: 'integer',
      })
    )
    return columnDefs
  }, [showAssetManagerColumn])

  const rows = assetsPerformanceQuery.data
  const tableModel = useTable(rows, columns, {
    canRowExpand,
    initialSort: [{ id: 'actualVsExpectedEnergy', desc: false }],
  })

  const modeledAssets = useMemo(
    () => {
      // unrwap the row to get to our orginal type
      // and filter out unmodled assets from the totals calculation
      return tableModel
        .getFilteredRowModel()
        .rows.map(r => r.original)
        .filter(r => r.isModeled)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableModel.getFilteredRowModel()]
  )

  const totalDefs = useMemo(() => {
    const defs: TotalDef<AssetPerformanceRow>[] = []
    if (showAssetManagerColumn) {
      defs.push({
        key: 'assetManagerName',
        format: () => 'TOTAL',
        className: 'tw-justify-start',
      })
    }
    defs.push(
      {
        key: 'systemSize',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'actualEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'expectedEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'predictedEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'actualVsPredictedEnergy',
        agg: Aggregations.weightedAverage('systemSize'),
        format: Formats.percent,
        tooltip: weightedAverageTooltip,
      },
      {
        key: 'actualVsExpectedEnergy',
        agg: Aggregations.weightedAverage('systemSize'),
        format: Formats.percent,
        tooltip: weightedAverageTooltip,
      },
      {
        key: 'solarResourceRatio',
        agg: Aggregations.weightedAverage('systemSize'),
        format: Formats.percent,
        tooltip: weightedAverageTooltip,
      },
      {
        key: 'openServiceActivityCount',
        agg: Aggregations.sum,
        format: Formats.integer,
      }
    )
    return defs
  }, [showAssetManagerColumn])

  const totals = useTotals<AssetPerformanceRow>(modeledAssets, totalDefs)

  // The following is a mapping of the columns to include in the CSV export.
  // key is the field in the JSON data and header is the value that gets output
  // in the CSV header row that the end user sees.
  const csvExportColumns: CsvColumnOption<AssetPerformanceRow>[] = useMemo(
    () => [
      ...((showAssetManagerColumn
        ? [{ key: 'assetManagerName', header: 'Asset Manager' }]
        : []) as CsvColumnOption<AssetPerformanceRow>[]),
      { key: 'assetName', header: 'Asset Name' },
      { key: 'systemSize', header: 'System Size (kWDC)' },
      { key: 'actualEnergy', header: 'Actual Energy (kWh)' },
      { key: 'predictedEnergy', header: 'Predicted Energy (kWh)' },
      { key: 'expectedEnergy', header: 'Expected Energy (kWh)' },
      {
        key: 'actualVsPredictedEnergy',
        header: 'Actual vs Predicted',
      },
      {
        key: 'actualVsExpectedEnergy',
        header: 'Actual vs Expected',
      },
      {
        key: 'solarResourceRatio',
        header: 'Solar Resource Ratio',
      },
      {
        key: 'openServiceActivityCount',
        header: 'No. of Open Service Activity',
      },
    ],
    [showAssetManagerColumn]
  )

  const [showDefinitionsModal, setShowDefinitionsModal] = useState(false)

  return (
    <PageBody bgColor="gray">
      <PageTitle>{branding?.name ? `${branding.name} Assets` : null}</PageTitle>
      <PageCard>
        <PageCard.Header>
          <PageCard.Title>Portfolio Overview</PageCard.Title>
          <div>
            <span className="tw-text-sm tw-font-normal tw-text-slate-500">
              Table is default sorted so the lowest performing assets are shown
              at the top (sorted by Actual vs Expected energy). Any assets that
              are still pending modeling review or that have meter errors are
              not incorporated into the Totals row.
            </span>{' '}
            <button
              className="tw-cursor-pointer tw-text-sm tw-font-normal tw-text-blue-600"
              onClick={() => {
                setShowDefinitionsModal(true)
              }}
            >
              View column definitions.
            </button>
          </div>
          <div className="tw-h-px tw-w-full tw-bg-slate-700/10" />
          <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-gap-4 tw-text-base tw-font-normal tw-text-gray-500">
            <div className="tw-grow">
              <SearchInput
                value={tableModel.globalFilter}
                onChange={value => {
                  tableModel.setGlobalFilter(String(value))
                }}
                placeholder="Search"
              />
            </div>
            <div className="tw-space-x-4">
              <DateRangeFilter
                currentMonthPosition="right"
                isoMinDate={moment()
                  .startOf('day')
                  .subtract(18, 'months')
                  .format(ISO_DATE_FORMAT)}
                isoMaxDate={moment()
                  .startOf('day')
                  .subtract(1, 'day')
                  .format(ISO_DATE_FORMAT)}
                isoStartDate={selectedDateRange.isoStartDate}
                isoEndDate={selectedDateRange.isoEndDate}
                presets={[
                  yesterdayPreset(),
                  lastNDaysPreset(7),
                  lastNDaysPreset(30),
                  lastNDaysPreset(60),
                  lastNMonthsPreset(3),
                  lastNMonthsPreset(6),
                  lastNMonthsPreset(12),
                  lastNMonthsPreset(18),
                ]}
                onApply={(isoStartDate, isoEndDate) => {
                  setSelectedDateRange({
                    isoStartDate,
                    isoEndDate,
                  })
                }}
              />
              <Tooltip
                content={`Export current table\nview, including any\napplied filters`}
              >
                <ExportCsvButton
                  fileName="portfolio_overview"
                  columns={csvExportColumns}
                  rows={() =>
                    tableModel.getSortedRowModel().rows.map(r => r.original)
                  }
                />
              </Tooltip>
            </div>
          </div>
        </PageCard.Header>
        <div className="tw-flex tw-flex-row tw-justify-end tw-gap-4 tw-py-4 tw-pr-6 tw-font-sans tw-text-base tw-font-normal tw-text-gray-500">
          <div>
            Showing: {tableModel.getFilteredRowModel().rows.length} of{' '}
            {rows?.length ?? 0}
          </div>
        </div>
        <div className="tw-mx-4 tw-overflow-auto">
          {assetsPerformanceQuery.isFetching && <LoadingPage />}
          {assetsPerformanceQuery.isFetched && (
            <Table
              className="tw-shrink"
              model={tableModel}
              totals={totals}
              error={assetsPerformanceQuery.isError}
              renderExpandedRow={({ row }) => {
                return <AssetTicketsTable assetId={row.original.assetId} />
              }}
              noDataMessage={
                <div className="tw-flex tw-min-h-[300px] tw-flex-col tw-items-center tw-justify-center tw-gap-6">
                  <div className="tw-text-xl">No Assets Found</div>
                  <div className="tw-text-base tw-text-gray-400">
                    Try adjusting your search filters
                  </div>
                </div>
              }
            />
          )}
        </div>
      </PageCard>
      {showDefinitionsModal && (
        <Modal
          show={true}
          onClose={() => {
            setShowDefinitionsModal(false)
          }}
        >
          <Modal.Header
            className="tw-flex tw-flex-row tw-justify-start tw-gap-3"
            onClose={() => {
              setShowDefinitionsModal(false)
            }}
          >
            <div className="tw-align-middle">
              <Icon icon={infoIcon} height={28} className="tw-text-gray-500" />
            </div>
            <div className="tw-align-middle">Definitions</div>
          </Modal.Header>
          <Modal.Body>
            <h6 className="tw-mb-0 tw-font-bold tw-text-gray-900">
              Actual Energy (kWh)
            </h6>
            <p className="tw-text-slate-500">
              The energy generated by a system as measured by on-site energy
              metering hardware.
            </p>

            <h6 className="tw-mb-0 tw-font-bold tw-text-gray-900">
              Predicted Energy (kWh)
            </h6>
            <p className="tw-text-slate-500">
              The energy Omnidian predicts a system will generate using
              Omnidian’s performance model, historical average weather data, the
              system hardware configuration, and predicted loss factors as
              inputs.
            </p>

            <h6 className="tw-mb-0 tw-font-bold tw-text-gray-900">
              Expected Energy (kWh)
            </h6>
            <p className="tw-text-slate-500">
              The energy a system should produce using actual weather
              measurements and Omnidian&apos;s Predicted Energy model.
            </p>

            <h6 className="tw-mb-0 tw-font-bold tw-text-gray-900">
              Solar Resource Ratio (%)
            </h6>
            <p className="tw-text-slate-500">
              The Actual Plane of Array (POA) divided by the Predicted POA.
            </p>

            <h6 className="tw-mb-0 tw-font-bold tw-text-gray-900">
              {' '}
              Open Tickets
            </h6>
            <p className="tw-text-slate-500">
              The count of tickets that are in any status other than “closed” or
              “solved”.
            </p>
          </Modal.Body>
          <Modal.Footer className="tw-justify-end">
            <Button
              onClick={() => {
                setShowDefinitionsModal(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </PageBody>
  )
}

function AssetNameCell(
  ctx: Readonly<CellContext<AssetPerformanceRow, unknown>>
): React.JSX.Element {
  const assetId = ctx.row.original.assetId
  const assetName = ctx.getValue<Maybe<string>>()

  if (ctx.row.original.isModeled)
    return <AssetDetailLink assetId={assetId}>{assetName}</AssetDetailLink>

  return (
    <div className="tw-inline-flex tw-h-4 tw-flex-row tw-justify-between tw-gap-1 tw-whitespace-nowrap">
      <Tooltip content={'This asset excluded\nfrom Totals'}>
        <Icon
          icon={warnIcon}
          width={16}
          height={16}
          className="tw-m-0 tw-inline tw-size-4 tw-p-0 tw-text-yellow-400"
        />
      </Tooltip>
      <AssetDetailLink assetId={assetId}>{assetName}</AssetDetailLink>
    </div>
  )
}

interface AssetTicketsTableProps {
  assetId: number
}

function AssetTicketsTable({
  assetId,
}: Readonly<AssetTicketsTableProps>): React.JSX.Element {
  const assetServiceActivityQuery = useAssetServiceActivityQuery(assetId)

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(
    ServiceActivityPageName.portfolioOverview
  )

  const rows = assetServiceActivityQuery.data?.data
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }

  return (
    <div className="tw-m-0 tw-bg-gray-100 tw-px-8 tw-py-4 tw-drop-shadow-md">
      <Table
        className="tw-m-0 tw-shrink tw-overflow-hidden tw-rounded-xl"
        model={tableModel}
        loading={assetServiceActivityQuery.isFetching}
        error={assetServiceActivityQuery.isError}
        rowClassName={() => 'hover:tw-bg-slate-50'}
        onRowClick={handleClickRow}
        noDataMessage={
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
            <div className="tw-text-xl">No service activity</div>
            <div className="tw-text-base tw-text-gray-400">
              No service activity were found for this asset.
            </div>
          </div>
        }
      />
      {selectedServiceActivity && (
        <ServiceActivityModal
          serviceActivity={selectedServiceActivity}
          onClose={handleCloseModal}
        />
      )}
    </div>
  )
}
