import { PageCard } from '@/components/cards/PageCard.tsx'
import ServiceActivityTableControls from '@/pages/serviceActivity/shared/ServiceActivityTableControls.tsx'
import useResidentialServiceActivityTableColumns from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableColumns.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useMemo } from 'react'
import { useResidentialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import Table from '@/components/core/table/Table.tsx'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import SnapshotCard from '@/pages/serviceActivity/residential/snapShots/components/SnapshotCard.tsx'
import { AssetStage } from '@/generated'
import ActiveIcon from '@/pages/serviceActivity/residential/snapShots/icons/ActiveIcon.svg'
import PerformanceVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/PerformanceVerificationIcon.svg'
import MetadataVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/MetadataVerificationIcon.svg'
import useResidentialServiceActivityTableFilters from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableFilters.tsx'
import PageBody from '@/pages/PageBody.tsx'

export default function ResidentialServiceActivityPage() {
  const client = 'EverBright'
  const { data, isLoading, isError } = useResidentialServiceActivityQuery()
  const rows = useMemo(
    () => data?.serviceActivity ?? [],
    [data?.serviceActivity]
  )
  const columns = useResidentialServiceActivityTableColumns()
  const filterDefs = useResidentialServiceActivityTableFilters()
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
    columnVisibility: {
      pendingClientAction: false,
      ticketType: false,
      createdAt: false,
      updatedAt: false,
      stage: false,
    },
  })

  const snapshots = useMemo(() => {
    return {
      active: data?.snapshots?.find(
        item => item.assetStage === AssetStage.Active
      ),
      metadataVerification: data?.snapshots?.find(
        item => item.assetStage === AssetStage.MetadataVerification
      ),
      pvt: data?.snapshots?.find(item => item.assetStage === AssetStage.Pvt),
    }
  }, [data?.snapshots])

  return (
    <PageBody bgColor="gray">
      <div className="tw-mb-8 tw-flex tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following views show all open service activity (tickets) for
            &nbsp;{client} assets
          </div>
        </div>
      </div>
      <div className="tw-mb-8 tw-flex tw-w-full tw-justify-between tw-gap-8">
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.metadataVerification}
            icon={<img alt="icon" src={MetadataVerificationIcon} />}
            description="Assets in Metadata Verification"
            ticketLabel="Metadata Verification Tickets"
            percentLabel="Percent of assets in Metadata Verification with 1+ tickets"
          />
        </div>
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.pvt}
            icon={<img alt="icon" src={PerformanceVerificationIcon} />}
            description="Assets in Performance Verification"
            ticketLabel="Performance Verification Testing Tickets"
            percentLabel="Percent of assets in PVT with 1+ tickets"
            showAdditionalFields
          />
        </div>
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.active}
            icon={<img alt="icon" src={ActiveIcon} />}
            description="Active Assets"
            ticketLabel="Tickets for Active assets"
            percentLabel="Percent of active assets with 1+ tickets"
            showAdditionalFields
          />
        </div>
      </div>
      <PageCard>
        <PageCard.Header>
          <ServiceActivityTableControls
            tableModel={tableModel}
            rowData={rows}
            filterDefs={filterDefs}
          />
        </PageCard.Header>
        <div className="tw-p-4">
          <Table
            className="tw-shrink"
            model={tableModel}
            loading={isLoading}
            error={isError}
            rowClassName={() => 'hover:tw-bg-slate-50'}
            noDataMessage={
              <ServiceActivityTableNoDataMessage tableModel={tableModel} />
            }
          />
        </div>
      </PageCard>
    </PageBody>
  )
}
