import SearchInput from '@/components/core/table/SearchInput.tsx'
import {
  FilterDrawerFilterDef,
  FiltersDrawerButton,
} from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import {
  CsvColumnOption,
  ExportCsvButton,
} from '@/components/export/ExportCsvButton.tsx'
import { formatCSVRow } from '@/helpers/ServiceActivityHelper.ts'
import { ActiveFilters } from '@/components/core/drawers/filter/ActiveFilters.tsx'
import { TableModel } from '@/components/core/table/useTable.tsx'
import { useTableFilterState } from '@/components/core/table/filters/useTableFitlerState.ts'
import { BaseServiceActivity } from '@/generated'

type Props<T> = {
  tableModel: TableModel<T>
  rowData?: Array<T>
  filterDefs?: Array<FilterDrawerFilterDef<T>>
  csvOptions?: {
    exportCsvColumns: Array<CsvColumnOption<T>>
    exportFileName?: string
  }
}
export default function ServiceActivityTableControls<
  T extends BaseServiceActivity,
>({ tableModel, rowData, filterDefs, csvOptions }: Readonly<Props<T>>) {
  const [tableFilterState, tableFilterStateActions] =
    useTableFilterState(tableModel)
  const disableFilterControls = rowData?.length === 0
  return (
    <>
      <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-gap-4 tw-text-base tw-font-normal tw-text-gray-500">
        <div className="tw-grow">
          <SearchInput
            value={tableModel.globalFilter}
            onChange={value => {
              tableModel.setGlobalFilter(String(value))
            }}
            placeholder="Search"
            disabled={disableFilterControls}
          />
        </div>
        <div className="tw-space-x-4">
          {filterDefs && (
            <FiltersDrawerButton
              filterDefs={filterDefs}
              rowData={rowData}
              appliedFilters={tableFilterState.activeFilters}
              disabled={disableFilterControls}
              onApplyFilters={tableFilterStateActions.applyFilters}
            />
          )}

          {csvOptions && (
            <Tooltip
              content={`Export current table\nview, including any\napplied filters`}
            >
              <ExportCsvButton
                fileName={csvOptions.exportFileName ?? 'service_activity'}
                columns={csvOptions.exportCsvColumns}
                rows={() =>
                  tableModel
                    .getSortedRowModel()
                    .rows.map(r => formatCSVRow<T>(r.original))
                }
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-flex-row tw-items-end tw-justify-end tw-gap-4">
        {filterDefs && tableFilterState.hasActiveFilters && (
          <div className="tw-grow">
            <ActiveFilters
              filterDefs={filterDefs}
              appliedFilters={tableFilterState.activeFilters}
              onClearFilter={tableFilterStateActions.clearFilter}
              onClearAll={tableFilterStateActions.clearAllFilters}
            />
          </div>
        )}
        <div className="tw-text-gray-500">
          Service Activity:&nbsp;
          {tableModel.getFilteredRowModel().rows.length}
        </div>
      </div>
    </>
  )
}
