import { AllDisabledFlags } from '@/helpers/FeatureFlags.tsx'
import { useFeatureFlagQuery } from '@/api/SpaQueries.ts'
import { LoadingPage } from '@/pages/LoadingPage.tsx'
import { Navigate, Route } from 'react-router-dom'
import { FeatureFlags } from '@/generated'
import { RequireFeature } from '@/components/feature-flags/RequireFeature.tsx'
import NotFoundPage from '@/pages/NotFoundPage.tsx'
import { AssetPerformanceTab } from '@/pages/assetdetail/AssetPerformanceTab.tsx'
import React from 'react'
import { AssetServiceActivityTab } from './AssetServiceActivityTab'
import AssetDetailPageWrapper from '@/pages/assetdetail/AssetDetailPageWrapper.tsx'
import AssetDetailsTab from './v2/AssetDetailsTab'

export const AssetDetailRoute = '/assets/:assetId'
export const AssetDetailsTabRoute = 'details'
export const AssetPerformanceTabRoute = 'performance'
export const AssetServiceActivityTabRoute = 'service-activity'

export const AssetDetailRoutes: React.JSX.Element = (
  <Route
    path={AssetDetailRoute}
    element={
      <RequireFeature feature={'assetDetailEnabled'} denied={<NotFoundPage />}>
        <AssetDetailPageWrapper />
      </RequireFeature>
    }
  >
    <Route index element={<AssetDefaultRoute />} />
    <Route
      path={AssetDetailsTabRoute}
      element={
        <RequireFeature feature="isResidentialClient" denied={<NotFoundPage />}>
          <AssetDetailsTab />
        </RequireFeature>
      }
    />
    <Route
      path={AssetPerformanceTabRoute}
      element={
        <RequireFeature
          feature="assetPerformanceEnabled"
          denied={<NotFoundPage />}
        >
          <AssetPerformanceTab />
        </RequireFeature>
      }
    />
    <Route
      path={AssetServiceActivityTabRoute}
      element={<AssetServiceActivityTab />}
    />
  </Route>
)

export function AssetDefaultRoute() {
  const featureFlagsQuery = useFeatureFlagQuery()
  if (featureFlagsQuery.isLoading) {
    return <LoadingPage />
  }
  const defaultRoute = getDefaultRoute(
    featureFlagsQuery.data ?? AllDisabledFlags
  )
  return <Navigate replace to={defaultRoute} />
}

function getDefaultRoute(featureFlags: FeatureFlags): string {
  const { assetPerformanceEnabled, isResidentialClient } = featureFlags
  if (isResidentialClient) {
    return AssetDetailsTabRoute
  } else if (assetPerformanceEnabled) {
    return AssetPerformanceTabRoute
  } else {
    return AssetServiceActivityTabRoute
  }
}
