import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import twoValuesColumn from '@/pages/serviceActivity/residential/tableComponents/twoValuesColumn.tsx'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import {
  DateFormat,
  getRelativeTimeString,
} from '@/helpers/NativeDateTimeHelpers.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'
import { ResidentialServiceActivity } from '@/generated'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'
import { serviceActivityPendingClientActionFilterDataAccessor } from '@/features/serviceactivity/serviceActivityFilters.ts'
import { dateRangeFilterFn } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'

export default function useResidentialServiceActivityTableColumns() {
  return useMemo<ColumnDef<ResidentialServiceActivity>[]>(
    () => [
      twoValuesColumn({
        accessorKey: 'id',
        primaryHeader: 'Ticket Id',
        secondaryHeader: 'Ticket Type',
        accessorFn: ctx => {
          const { id, ticketType } = ctx.row.original
          return {
            primary: (
              <span className="tw-text-blue-600 tw-underline">{id}</span>
            ),
            secondary: ticketType,
          }
        },
      }),
      {
        accessorKey: 'priority',
        header: 'Priority',
        cell: TicketPriorityCell,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority?.toString()
        ),
        filterFn: facetFilterFn,
      },
      twoValuesColumn({
        accessorKey: 'customerName',
        primaryHeader: 'Customer Name',
        secondaryHeader: 'Asset ID',
        accessorFn: ctx => ({
          primary: (
            <AssetDetailLink assetId={ctx.row.original.assetId}>
              {ctx.row.original.customerName}
            </AssetDetailLink>
          ),
          secondary: ctx.row.original.assetId,
        }),
      }),
      {
        id: 'creationReason',
        accessorKey: 'creationReason',
        header: 'Creation Reason',
        filterFn: facetFilterFn,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'Ticket Status',
        filterFn: facetFilterFn,
      },
      twoValuesColumn({
        accessorKey: 'createdAt',
        primaryHeader: 'Ticket Age',
        secondaryHeader: 'Created Date',
        accessorFn: ctx => {
          const createdAtDate = new Date(ctx.row.original.createdAt)
          const age = getRelativeTimeString(createdAtDate)
          const dateString = DateFormat.format(createdAtDate)
          return {
            primary: age,
            secondary: dateString,
          }
        },
        enableSorting: true,
      }),
      textColumn({
        key: 'otherOpenServiceActivitiesCount',
        header: 'Other OpenTickets',
        cell: ctx => (
          <div className="tw-flex tw-flex-row-reverse">
            <Badge color="gray" className="tw-w-2">
              {ctx.row.original.otherOpenServiceActivitiesCount}
            </Badge>
          </div>
        ),
      }),
      {
        id: 'pendingClientAction',
        accessorKey: 'pendingClientAction',
        header: 'Pending Client Action',
        filterFn: facetFilterFn,
        accessorFn: serviceActivityPendingClientActionFilterDataAccessor,
      } as ColumnDef<ResidentialServiceActivity>,
      {
        id: 'ticketType',
        accessorKey: 'ticketType',
        header: 'Ticket Type',
        filterFn: facetFilterFn,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        enableSorting: true,
        header: 'Creation Date',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        enableSorting: true,
        header: 'Last Updated',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        id: 'stage',
        accessorKey: 'stage',
        header: 'Stage',
        filterFn: facetFilterFn,
      },
    ],
    []
  )
}
