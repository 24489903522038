import { Badge } from '@/components/core/badges/Badge.tsx'

export type SnapshotValueDisplayType =
  | 'default'
  | 'primary'
  | 'danger'
  | 'badge'
type Props = {
  value: string | number
  displayType?: SnapshotValueDisplayType
}
export default function SnapshotValue({
  value,
  displayType = 'default',
}: Readonly<Props>) {
  if (displayType === 'badge') {
    return <Badge color="gray">{value}</Badge>
  }
  if (displayType === 'danger') {
    return <div className="tw-font-semibold tw-text-red-700">{value}</div>
  }
  return (
    <div
      className={
        displayType === 'primary'
          ? 'tw-text-2xl tw-font-bold tw-text-gray-900'
          : ''
      }
    >
      {value}
    </div>
  )
}
