import { useAuth, useUser } from '@/components/auth/AuthHooks'
import { Menu, Transition } from '@headlessui/react'
import { Avatar } from '@/components/core/avatar/Avatar'
import { twJoin } from 'tailwind-merge'
import { useBrandingQuery } from '@/api/ApiQueries.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import { useContext, useState } from 'react'
import { ClientProfileSelectionModal } from '@/components/modals/clientprofile/ClientProfileSelectionModal.tsx'
import { ClientProfileContext } from '@/contexts/ClientProfileProvider.tsx'
import swapHoriz from '@iconify/icons-material-symbols/swap-horiz'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDefaultRoute } from '@/helpers/NavigationHooks.ts'
import { useClientProfileIdsQuery } from '@/api/SpaQueries.ts'
import Logo from '@/assets/omnidian-logo.svg?react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export function SiteHeader() {
  const auth = useAuth()
  const { data: branding } = useBrandingQuery()
  const { serviceActivityEnabled, portfolioOverviewEnabled } = useFeatureFlags()
  const navigate = useNavigate()
  const defaultRoute = useDefaultRoute()

  const { activeClientProfileId, setActiveClientProfileId } =
    useContext(ClientProfileContext)
  const clientProfileIdsQuery = useClientProfileIdsQuery()
  const [showClientModal, setShowClientModal] = useState<boolean>(false)
  const clientProfileIds = clientProfileIdsQuery.data ?? []

  return (
    <div className="tw-z-[500] tw-flex tw-items-center tw-gap-4 tw-self-stretch tw-border-b tw-border-gray-200 tw-bg-white tw-px-20 tw-py-4">
      <Logo />
      {branding?.logoUrl && (
        <>
          <div className="tw-h-[32px] tw-w-0.5 tw-border-l tw-border-slate-300"></div>
          <img
            alt={`${branding.name} Logo`}
            src={branding.logoUrl}
            className="tw-max-h-[32px] tw-max-w-[126px]"
          />
        </>
      )}
      <div className="tw-shrink tw-grow tw-basis-0"></div>
      <ul className="tw-inline-flex tw-gap-6">
        {portfolioOverviewEnabled && (
          <HeaderNavLink path="/portfolio" title="Portfolio Overview" />
        )}
        {serviceActivityEnabled && (
          <HeaderNavLink path="/service-activity" title="Service Activity" />
        )}
      </ul>
      {clientProfileIds.length > 1 && (
        <div>
          <Button
            icon={swapHoriz}
            color="light"
            onClick={() => {
              setShowClientModal(true)
            }}
          >
            Switch client
          </Button>
        </div>
      )}
      {showClientModal && (
        <ClientProfileSelectionModal
          clientProfileIds={clientProfileIds}
          onSetClientProfileId={clientProfileId => {
            setShowClientModal(false)
            if (activeClientProfileId !== clientProfileId) {
              setActiveClientProfileId(clientProfileId)
              navigate(defaultRoute)
            }
          }}
          onClose={() => {
            setShowClientModal(false)
          }}
        />
      )}
      <div className="tw-cursor-default tw-select-none">
        {auth.isAuthenticated && <UserMenu />}
      </div>
    </div>
  )
}

function UserMenu() {
  const auth = useAuth()
  const user = useUser()

  const logout = async () => {
    await auth.signOut()
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            data-testid="userMenu"
            className="tw-border-0 tw-bg-transparent"
          >
            <Avatar fullName={user.name} />
          </Menu.Button>
          <Transition
            show={open}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            {auth.isAuthenticated && (
              <Menu.Items
                static
                className="tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-origin-top-right tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200 tw-bg-white tw-shadow-lg tw-outline-none"
              >
                <div className="tw-px-4 tw-py-3">
                  <p className="tw-text-base tw-font-bold tw-leading-5 tw-text-black">
                    {user.name}
                  </p>
                  <p className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-700">
                    {user.email}
                  </p>
                </div>
                <div className="tw-border-t tw-border-solid tw-py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          void logout()
                        }}
                        className={twJoin(
                          `tw-flex tw-w-full tw-justify-start tw-px-4 tw-py-2 tw-text-left tw-text-sm tw-leading-5`,
                          active
                            ? 'tw-bg-gray-100 tw-text-gray-900'
                            : 'tw-text-gray-700'
                        )}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            )}
          </Transition>
        </>
      )}
    </Menu>
  )
}

interface HeaderNavLinkProps {
  title: string
  path: string
}

function HeaderNavLink({ title, path }: Readonly<HeaderNavLinkProps>) {
  return (
    <li className="tw-m-0 tw-cursor-pointer tw-select-none tw-list-none tw-p-0">
      <NavLink
        to={path}
        className={({ isActive }) =>
          twMerge(
            'tw-text-center tw-text-sm tw-font-medium',
            isActive
              ? 'tw-text-blue-600'
              : 'tw-text-gray-500 hover:tw-text-gray-900'
          )
        }
      >
        {title}
      </NavLink>
    </li>
  )
}
